<template>
  <div>
    <div class="pay-transition" v-if="arousing">
      <img class="wx-pay-icon" src="../assets/images/wx-pay.png" alt="wx-pay">
      <p class="wx-pay-name">微信支付</p>
      <p class="loading">正在唤起 <span class="time">{{ time }}s</span></p>
      <ul class="dots">
        <li class="dot first-dot"></li>
        <li class="dot second-dot"></li>
        <li class="dot thirdly-dot"></li>
      </ul>
      <p class="footer">未自动唤起？点此 <a class="pay" href="javascript:;" @click="refreshCode">立即支付</a></p>
    </div>
    <div class="gzh-msg-pay" v-else>
      <div v-if="!paySuccess" class="btn pay-btn" @click="refreshCode">立即支付</div>
      <div v-else class="pay-success">
        <div class="pay-success-icon">
          <van-icon name="success"/>
        </div>
        支付成功
        <div class="btn return-btn" @click="goWxApp">返回</div>
        <div class="tip">*或沿屏幕左侧滑动两遍即可返回</div>
      </div>
    </div>
  </div>
</template>

<script>
import {gzhMsgPayV2Api} from "../api/v2/gzhMsgPayV2Api";
import wxValidate from "../config/wx.validate";
import {sleep} from "../config/util";

export default {
  mixins: [wxValidate],
  data() {
    return {
      code: '',
      paySuccess: false,
      arousing: true,
      time: 3,
      timer: null
    }
  },


  async created() {
    this.code = this.$route.query.code
    if (!this.code) {
      return this.refreshCode()
    }
    this.arousing = true
    this.timer = setInterval(() => {
      if (this.time === 0) {
        this.time = 0
        clearInterval(this.timer)
        return
      }
      this.time--
    }, 1000);
    await this.wxSignature()
    await this.startPay()
  },


  beforeDestroy() {
    clearInterval(this.timer)
  },


  methods: {

    refreshCode() {
      return window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.appId + '&redirect_uri=' + encodeURI(window.location.href) + '&response_type=code&scope=snsapi_base&state=STATE#wechart_redirect';
    },


    async startPay() {
      await sleep(500)
      const res = await gzhMsgPayV2Api.startPay(this.code, this.$route.query.id)
      this.arousing = false
      clearInterval(this.timer)
      wx.chooseWXPay({
        timestamp: res.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
        package: res.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: res.paySign, // 支付签名
        success: async (r) => {
          this.$loading('请求中');
          await sleep(1500);
          this.$loading.close();
          this.$toast('支付成功')
          this.paySuccess = true
          // await this.goWxApp()
        },
        fail: (e) => {
          self.$toast.error(e.errMsg);
        },
      });
    },


    async goWxApp() {
      window.location.href = await gzhMsgPayV2Api.findUrlScheme(this.$route.query.id)
    }

  }


}
</script>

<style scoped lang="less">
.pay-transition {
  width: 100vw;
  height: 100vh;
  text-align: center;
  .wx-pay-icon {
    margin-top: 20vh;
    width: 50px;
    margin-bottom: 20px;
  }
  .wx-pay-name {
    font-size: 20px;
    text-align: center;
    color: #dadada;
    margin-bottom: 8px;
  }
  .loading {
    color: #555;
    text-align: center;
    font-size: 12px;
    padding: 12px 0 16px 0;
    .time {
      color: #d2d2d2;
      font-size: 12px;
    }
  }
  .dots {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .dot {
      width: 5px;
      height: 5px;
      background-color: #eee;
      border-radius: 100%;
      margin-right: 3px;
    }
    .first-dot {
      background-color: #999;
      animation: first-dot 1s infinite;
    }
    .second-dot {
      background-color: #d2d2d2;
      animation: second-dot 1s infinite;
    }
    .thirdly-dot {
      background-color: #eee;
      animation: thirdly-dot 1s infinite;
    }
  }
  .footer {
    width: 100%;
    height: 50px;
    font-size: 12px;
    color: #555;
    text-align: center;
    position: fixed;
    bottom: 0;
    .pay {
      color: var(--zhifubao);
      font-size: 12px;
    }
  }
}

@keyframes first-dot
{
  0% { background-color: #999; }
  50% { background-color: #d2d2d2; }
  100% { background-color: #eee; }
}
@keyframes second-dot
{
  0% { background-color: #d2d2d2; }
  50% { background-color: #eee; }
  100% { background-color: #999; }
}
@keyframes thirdly-dot
{
  0% { background-color: #eee; }
  50% { background-color: #d2d2d2; }
  100% { background-color: #999; }
}

.gzh-msg-pay {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  padding: 8px 32px;
  font-size: 12px;
  border-radius: 100000px;
}

.return-btn {
  margin-top: 32px;
  background: #f3f5f7;
}

.pay-btn {
  background: #151D36;
  color: #fff;
}

.tip{
  font-size: 12px;
  color: #999;
  margin-top: 16px;
}

.pay-success-icon {
  background: #5fce81;
  width: 60px;
  height: 60px;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 50px;
  margin-bottom: 32px;
}


.pay-success {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
