import {v2Request} from "./v2Request";

export const gzhMsgPayV2Api = {

  startPay(code, id){
    return v2Request.post(`/gzhMsgPay/startPay`,{code,id})
  },

  findUrlScheme(id) {
    return v2Request.post(`/gzhMsgPay/findUrlScheme`,{id})
  }
}
